import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../components/layout"
import BgImage from '../components/bg-image'

const Team = () => (
  <Layout>
    <Helmet title="Our Team" />
    <BgImage image="/assets/our-team.jpg" className="top">
      <h1>Our Team</h1>
    </BgImage>
    <section className="content">
      <div className="team-member">
        <img alt="Errol Samuel" src="/assets/user.png" />
        <div className="team-member-body">
          <h3>Errol Samuel</h3>
          <p className="small">Managing Director</p>
          <p>
            Errol studied Chemical Engineering at Imperial College in the United
            Kingdom before coming to Australia. Once in Australia he used his
            engineering in the water industry. After doing work in various
            government roles Errol decided to become a private consultant. Then
            17 years ago he decided to use his understanding of water to launch
            himself into the pool industry. Errol’s special interest areas
            include hydraulics, automation systems and water chemistry for both
            residential and commercial swimming pools.
          </p>
        </div>
      </div>
      <div className="team-member">
        <img alt="Gareth Samual" src="/assets/user.png" />
        <div className="team-member-body">
          <h3>Gareth Samual</h3>
          <p>
            Gareth studied Arts at the University of New South Wales and all the
            while working in the family pool business casually. After study he
            decided to join the business full time. He has since studied
            Waterproofing (Master Builders Association), Plastering (TAFE) and a
            Certificate IV in Pool Building with the Swimming Pool & Spa
            Association of NSW. (SPASA). Gareth’s special interest areas include
            concrete spalling, water chemistry and poolscaping.
          </p>
        </div>
      </div>
      <div className="team-member">
        <img alt="Gareth Samual" src="/assets/user.png" />
        <div className="team-member-body">
          <h3>Billy</h3>
          <p>Billy studied to be an electrician in the United Kingdom.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default Team
